import { Controller } from 'stimulus';
import { v4 as uuidv4 } from 'uuid';

// Declare the type for the SignWellEmbed client
declare global {
  interface Window {
    SignWellEmbed?: any; // Use 'any' if you don't have exact types
  }
}

// If you want more specific typing, you can create an interface
interface SignWellEmbed {
  // Add known methods and properties here
  open: () => void;
  close: () => void;
  // ... other methods
}

export default class SignwellController extends Controller {
  static targets = ["signingContainer", "signatureIdInput", "form"]
  static values = { clientId: String, signingUrl: String, sandbox: Boolean }

  declare signingContainerTarget: HTMLElement
  declare signatureIdInputTarget: HTMLInputElement
  declare formTarget: HTMLFormElement
  declare signingUrlValue: string
  declare esignClient: SignWellEmbed
  declare sandboxValue: boolean

  connect() {
    // give the signing container a uuid based id
    this.signingContainerTarget.id = `signing-container-${uuidv4()}`

    this.esignClient = new SignWellEmbed({
      url: this.signingUrlValue,
      containerId: this.signingContainerTarget.id,
      allowCancel: false,
      debug: this.sandboxValue,
      testMode: this.sandboxValue,
      events: {
        completed: this.handleSignature.bind(this)
      },
    })

    this.esignClient.open()
  }

  handleSignature(data: any) {
    this.signatureIdInputTarget.value = this.signingUrlValue
    this.signingContainerTarget.remove()
    this.formTarget.submit()
  }
}